import actions from './actions';


const connect = (params) => (dispatch) => {
	console.info('[operation] connect()', params);
	return dispatch(actions.connect.request(params));
};

export {
	connect,
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getApiStatus } from '@@Redux/apiStatus';
import Modal from 'react-modal';


const propTypes = {
	message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '25%'
	},
};

const ProgressIndicator = ({
	message = null,
}) => {
	if (message) {
		return (
			<Fragment>
				<Modal
					isOpen={true}
					style={customStyles}
					contentLabel={message}
				>
					{/*<button onClick={closeModal}>close</button>*/}
					<div className="text-dark">
						{message}
					</div>
				</Modal>
			</Fragment>
		);
	}

	return null;
};

ProgressIndicator.propTypes = propTypes;

const mapStateToProps = (state) => {
	const apiStatus = getApiStatus(state);
	const message = Object.values(apiStatus).find(value => value);

	return {
		message,
	};
};

export default connect(
	mapStateToProps,
)(ProgressIndicator);

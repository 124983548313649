import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';

import {ChainId, DAppProvider} from '@usedapp/core';
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';

import configureStore, {history} from '@@Redux/store';

import App from '@@Components/App';

import './css/app.scss';


const firebaseConfig = {
	apiKey: "AIzaSyD5nQ6pv_fwoK_6ys3mJPKddeTOqAuf6zA",
	authDomain: "bingotown-19068.firebaseapp.com",
	projectId: "bingotown-19068",
	storageBucket: "bingotown-19068.appspot.com",
	messagingSenderId: "1058560840153",
	appId: "1:1058560840153:web:7f4f0ad4665b05479f49c8",
	measurementId: "G-X6ECQXY2C2"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// TODO: verify this is needed and/or change accordingly
const config = {
	readOnlyChainId: ChainId.Mainnet,
	readOnlyUrls: {
		[ChainId.Mainnet]: 'https://mainnet.infura.io/v3/651142e4209b4e829d5b2d435c611975',
	},
};

ReactDOM.render(
	<Provider store={configureStore()}>
		<ConnectedRouter history={history}>
			{/*<DAppProvider config={config}>*/}
				<App/>
			{/*</DAppProvider>*/}
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root'),
);

import React, { useEffect, useState, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';

import Modal from 'react-modal';

import ErrorFallback from '@@Components/ErrorFallback';
import Fairness from '@@Components/Fairness';
import FAQ from '@@Components/FAQ';
import Footer from '@@Components/Footer';
import Home from '@@Components/Home';
import Loading from '@@Components/Loading';
import Header from '@@Components/Header';
import Terms from '@@Components/Terms';

import Draws from '@@Components/Draws';
import Sounds from '@@Components/Sounds';
import Welcome from '@@Components/Welcome';
import WalletConnection from '@@Components/WalletConnection';

import config from '@@Config';
import { colors } from '@@Constants';
import { operations as blockchainOperations, selectors as blockchainSelectors } from '@@Redux/blockchain';

const App = ({
	connect,
}) => {

	useEffect(() => {
		// connect();

	}, [connect]);

	return (
		<Fragment>

			<ErrorBoundary
				FallbackComponent={ErrorFallback}
				onReset={() => {}}
			>

				<Helmet
					titleTemplate={`%s - ${config.basePageTitle}`}
					defaultTitle={config.basePageTitle}
				>
					<meta name="theme-color" content={colors.BLUE.hex} />
				</Helmet>

				{/*<Background />*/}

				<Loading />

				<Welcome />

				<Header />

				{/*<Draws />*/}


				<Sounds />

				<main className="pt-5">
					<Switch>
						<Route exact path="/" render={(props) => (<Home {...props} />)}/>
						<Route path="/faq" render={(props) => (<FAQ {...props} />)}/>
						<Route path="/terms" render={(props) => (<Terms {...props} />)}/>
						<Route path="/provable-fairness" render={(props) => (<Fairness {...props} />)}/>
					</Switch>
				</main>

				<Footer />

			</ErrorBoundary>
		</Fragment>
	);
}

const mapState = (/* state */) => {
	return {};
};

const mapDispatch = {
	connect: blockchainOperations.connect,
};

export default connect(
	mapState,
	mapDispatch,
)(withRouter(App));


const { localStorage } = global;
const name = 'Bingotown';
const shortName = 'Bingotown';
const description = 'Bingotown NFT';
const basePageTitle = 'Welcome to Bingotown';

const ABI = require('../artifacts/contracts/Bingotown.sol/Bingotown.json');

module.exports = {
	basePageTitle,
	name,
	shortName,
	description,
	env: process.env.NODE_ENV,
	api: {
		hostname: process.env.API_BASE_URL,
		port: process.env.PORT,
		baseUrl: process.env.API_BASE_URL,
	},
	contract: {
		address: process.env.LOCAL_CONTRACT_ADDRESS,
		abi: ABI.abi,
	},
	token: {
		count: 9999,
		price: 0.05,
		currency: {
			symbol: 'ETH',
		},
	},
	baseURI: 'https://storage.googleapis.com/bingotown',
	recaptcha: {
		siteKey: process.env.RECAPTCHA_SITE_KEY,
	}
};

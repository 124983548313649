import React, { Fragment, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import Tuna from 'tunajs';

import music1 from '@@/audio/happy-hour-jazzy-summer-music-113220.m4a';
import crowd from '@@/audio/crowd_talking-6762.m4a';
// import output from '@@/audio/output.m4a';



// http://stackoverflow.com/a/22313408/1090298
const makeDistortionCurve = (amount) => {
	let k = typeof amount === 'number' ? amount : 0,
		n_samples = 44100,
		curve = new Float32Array(n_samples),
		deg = Math.PI / 180,
		i = 0,
		x;
	for (; i < n_samples; ++i) {
		x = i * 2 / n_samples - 1;
		curve[i] = (3 + k) * x * 20 * deg / (Math.PI + k * Math.abs(x));
	}
	return curve;
}

const Sounds = () => {

	const [isStarted, setIsStarted] = useState(false);

	let audioRef = React.createRef();
	let audioRef2 = React.createRef();
	let audioRef3 = React.createRef();

	window.addEventListener('click', () => {

		if (!isStarted) {

			setIsStarted(true);

			console.log('audioRef', audioRef.audioEl);
			audioRef.audioEl.current.play();
			audioRef.audioEl.current.volume = 0.5;

			audioRef2.audioEl.current.play();
			audioRef2.audioEl.current.volume = 0.75;

			// audioRef3.audioEl.current.play();
			// audioRef3.audioEl.current.volume = 0.4;

			const context = new (window.AudioContext || window.webkitAudioContext)();
			const source1 = context.createMediaElementSource(audioRef.audioEl.current);
			const source3 = context.createMediaElementSource(audioRef3.audioEl.current);

			const tuna = new Tuna(context);
			const overdrive = new tuna.Overdrive({
				outputGain: -42,           //-42 to 0 in dB
				drive: 1,                 //0 to 1
				curveAmount: 0.979,           //0 to 1
				algorithmIndex: 0,            //0 to 5, selects one of the drive algorithms
				bypass: 1
			});

			const pingPongDelay = new tuna.PingPongDelay({
				wetLevel: 0.666,       //0 to 1
				feedback: 0.666,       //0 to 1
				delayTimeLeft: 200,  //1 to 10000 (milliseconds)
				delayTimeRight: 400  //1 to 10000 (milliseconds)
			});

			const moog = new tuna.MoogFilter({
				cutoff: 0.75,    //0 to 1
				resonance: 4,   //0 to 4
				bufferSize: 4096  //256 to 16384
			});

			const compressor = new tuna.Compressor({
				threshold: -75,    //-100 to 0
				makeupGain: 1,     //0 and up (in decibels)
				attack: 1,         //0 to 1000
				release: 250,      //0 to 3000
				ratio: 4,          //1 to 20
				knee: 5,           //0 to 40
				automakeup: false, //true/false
				bypass: 0
			});

			const delay = new tuna.Delay({
				feedback: 0.5,    //0 to 1+
				delayTime: 1000,    //1 to 10000 milliseconds
				wetLevel: 0.5,     //0 to 1+
				dryLevel: 1,       //0 to 1+
				cutoff: 10000,      //cutoff frequency of the built in lowpass-filter. 20 to 22050
				bypass: 0
			});

			const gain = context.createGain();

			source1.connect(delay);
			delay.connect(context.destination);

			source3.connect(gain);
			gain.connect(overdrive);
			overdrive.connect(compressor);
			compressor.connect(pingPongDelay);
			pingPongDelay.connect(context.destination);
		}


	});

	return (
		<Fragment>
			<ReactAudioPlayer
				ref={(input) => {audioRef = input}}
				src={music1}
				autoPlay={true}
				controls={false}
				loop={true}
			/>

			<ReactAudioPlayer
				ref={(input) => {audioRef2 = input}}
				src={crowd}
				autoPlay={true}
				controls={false}
				loop={true}
			/>

			{/*<ReactAudioPlayer
				ref={(input) => {audioRef3 = input}}
				src={output}
				autoPlay={true}
				controls={false}
				loop={true}
			/>*/}
		</Fragment>
	)
}


export default Sounds;

import React from 'react';
import config from '@@Config';
import { Helmet } from 'react-helmet';

const Fairness = () => {
	return (
		<div className="container">

			<Helmet title="Provable Fairness" />

			<div className="row justify-content-center">
				<div className="col-md-8">

					<div className="mb-5">
						<h1>Provable Fairness</h1>
						<em>The goal of these measures is to ensure that this collection maintains its integrity and allow you to prove it remains unadulterated.</em>
					</div>

					<div className="mb-5">
						<h2 className="h3">Minting/Generation</h2>
						<ul>
							<li>A seed value will be generated on-chain for each token upon minting.</li>
							<li>This seed is generated using the minter's address, the block timestamp, block difficulty, the previous token's ID, and hashed using the <a href="https://en.wikipedia.org/wiki/SHA-3" target="_blank" rel="noopener nofollow noreferrer">keccak256</a> algorithm.</li>
							<li>In addition, this seed value can also be directly affected by the minter by a mechanism provided on the website.</li>
							<li>Each token's seed value can be viewed using the contract's <code>getTokenSeed()</code> function.</li>
							<li>The token's card numbers and artwork will be generated <em>after</em> the token is minted - using this immutable seed for all its random aspects.</li>
							<li>This seed-based generation utilizes a <a href="https://en.wikipedia.org/wiki/Mersenne_Twister" target="_blank" rel="noopener nofollow noreferrer">Mersenne Twister</a> pseudo-random number generator (PRNG) and produces repeatable and verifiable results.</li>
						</ul>
					</div>

					<div className="mb-5">
						<h2 className="h3">Drawing</h2>
						<ul>
							<li>E</li>
						</ul>
						<p>Each minted card token ID will then be 'dealt' sequentially-assigned metadata and artwork based on the offset of this new starting index.</p>
						<p>After the allow list sale is complete, project founders will receive a set of reserved cards (tokens) sequentially from the last token ID of the allow list sale.</p>
						<p>The public sale will continue this sequence until all {config.token.count} cards have been minted/dealt.</p>
					</div>

				</div>
			</div>

		</div>
	);
}

export default Fairness;

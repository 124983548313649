import React from 'react';
import config from '@@Config';
import { Helmet } from 'react-helmet';

const Terms = () => {
	return (
		<div className="container">

			<Helmet
				title="Terms of Conditions"
			/>

			<div className="row justify-content-center">
				<div className="col-md-8">

			<article>
				<h1>Bingotown Terms and Conditions</h1>

				<p>Last Updated: April 30, 2022</p>

				<p className="mb-5"><strong>These terms and conditions ("Terms & Conditions") govern your use of the Bingotown.fun website, including your purchase of any Bingotown NFTs; by using this website or attempting to purchase an Bingotown NFT, you accept these Terms & Conditions in full. If you disagree with these Terms & Conditions or any part of these Terms & Conditions, you must not use the Bingotown.fun website or attempt to purchase any Bingotown NFT.</strong></p>

				<h2>1. Definitions</h2>
				<p>“Art” means any art, designs, drawings, traits, layers, and other design elements that may be associated with a Licensed NFT that you own.</p>
				<p>“Content” means text, images, audio material, video material, audio-visual material, or other forms of media.</p>
				<p>“Licensed NFT” Means a non-fungible token that you Own and was originally minted on this Site. “NFT” means any blockchain-tracked, non-fungible token.</p>
				<p>“NFT Trading Platform” means a secure marketplace where NFTs are sold, transferred, and recorded on the applicable blockchain and such transactions can be proven by the applicable blockchain.</p>
				<p>“Own” or “Ownership” means, with respect to a Licensed NFT, a Licensed NFT that you have purchased through the Website or otherwise rightfully acquired from a legitimate source, where proof of purchase was recorded on the applicable blockchain and ownership of the Licensed NFT can be proven.</p>
				<p>“Owner” means someone who has rightful and legal Ownership of a Licensed NFT.</p>

				<h2>2. Your Acceptance</h2>
				<p>These Terms &amp; Conditions constitute an agreement ("Agreement") between Ninebolt, Inc. ("NINEBOLT"), the owner and operator of the Bingotown website, https://Bingotown.fun (the “Site” or “Website”), and you (“you”, “your” or “user(s)”), a user of the Site.</p>
				<p>Throughout this Agreement, the words “NINEBOLT,” “us,” “we,” and “our,” refer to our company, Ninebolt, Inc.</p>
				<p>BY ACCESSING THIS WEBSITE, YOU AGREE TO AND ACCEPT THESE TERMS AND CONDITIONS IN FULL AND WITHOUT RESERVATION. IF YOU DISAGREE WITH THESE TERMS &amp; CONDITIONS, YOU MAY NOT USE THIS WEBSITE OR ATTEMPT TO PURCHASE ANY Bingotown NFTS.</p>
				<p>We may amend this Agreement at any time so please come back and review these Terms &amp; Conditions often.</p>

				<h2>3. NINEBOLT Ownership</h2>
				<p>Unless otherwise stated, NINEBOLT and/or its licensors and affiliates own the intellectual property rights published on this website, including all text, data, graphics, photographs, images, audio, video, trademarks, service marks, trade names and
						other information, visual or other digital material, software (including source and object codes) and all
						other content or any description available on the Site or available via a link from Site to a page
						created by NINEBOLT on another website (collectively, the "NINEBOLT Content"). The NINEBOLT Content is the sole property
						of NINEBOLT and/or its licensors, affiliates, or third-party service providers.</p>

				<p>You acknowledge and
						agree that NINEBOLT and/or its licensors and affiliates own all legal rights, title, interest in the Art
						associated with any Licensed NFTs, and all intellectual property rights, including but not limited to,
						trademark and copyright rights, therein. Any rights provided to you upon purchase or ownership of a
						Licensed NFT are limited to those expressly stated herein. NINEBOLT reserves all rights and ownership to the
						Licensed NFTs and Art not specifically granted to the User in this Agreement.</p>

				<h2>4. Purchase</h2>
					<p>Any payments of financial transactions that you conduct or engage in through the Site are final. You
						acknowledge and understand that the Site cannot and will not reverse any payments or transactions. All
						purchases of Licensed NFTs, as well as any associated charges, are non-refundable.</p><p>You shall be
						responsible for all applicable taxes including any sales or compensating use tax or equivalent tax
						wherever such tax may arise.</p>

				<p>The user accepts and acknowledges that NINEBOLT and its Affiliates will not
						be responsible for any communication failures, disruptions, distortions, delays, or any other errors that
						may arise when you attempt to purchase any Licensed NFTS.</p>

				<h2>5. User Ownership</h2>

				<p>Upon you
						assuming Ownership of a Licensed NFT and accepting the Terms and Conditions of this Agreement, NINEBOLT shall
						grant you a non-exclusive, worldwide, royalty-free license to 1) use, display, or otherwise enjoy the Art
						of your Licensed NFT for your own personal use; 2) commercialize your Licensed NFT by producing and
						selling physical merchandise that portrays the Licensed NFT’s underlying Art in its entirety; 3) sell or
						transfer your Licensed NFTs to another party pursuant to Section 7 of this Agreement; and 4) use your
						Licensed NFT as part of a third-party website or application which permits the inclusion, involvement,
						and/or participation for your Licensed NFTs, provided that the website or application cryptographically
						permits and verifies each NFT owner’s rights and ownership to display the Art and the website or
						application ensures only the actual owner can display the Art.</p>

				<p>You understand and agree that these rights
						are licensed solely to the rightful and legal Owner of the Licensed NFT. Your licensed rights to the
						Licensed NFT will terminate upon the rightful and legal sale or transfer of your Licensed NFT in
						accordance with Sections 7 and 8 of this Agreement and the new Owner, upon their acceptance of the Terms
						and Conditions of this Agreement, shall receive these licensed rights.</p>

				<h2>6. Restrictions to Use</h2>
					<p>You understand and agree that any physical merchandise that you produce must incorporate every element
						and trait of your Licensed NFT in full. The individual layers and traits of the Licensed NFTs are not
						owned by you and are the sole property of NINEBOLT and/or its licensors and affiliates. Such commercialization
						rights are limited to the Licensed NFTs that you own.</p>

				<p>You further understand and agree that this license
						does not permit the ability to create any digital merchandise. The creation and minting of any new NFTs
						which are derivatives of your Licensed NFTs are expressly prohibited.</p>

				<p>You understand and
						acknowledge that you may not, nor permit any third party to do or attempt to do the foregoing without the
						express prior written consent from NINEBOLT; (1) modify the Art in your Licensed NFT in any way, including, without limitation, the shapes, designs, drawings, attributes,
						color schemes, or design elements of the Licensed NFT; (2) produce any merchandise in connection with
						your Licensed NFT that depicts lewd behavior, illegality, hatred, intolerance, cruelty, vulgarity,
						pornographic or other “adult-only” material, discrimination, or otherwise harmful material to the Art and
						Bingotown Brand; (3) or attempt to trademark, copyright, or otherwise attempt to acquire
						additional intellectual property rights in your Licensed NFT.</p>

				<h2>7. Transfer</h2>
				<p>You have the limited right to transfer or sell your Licensed NFTs on an NFT Trading
						Platform, provided that the transferee or purchaser understands and accepts the terms of this Agreement
						and the terms of the NFT trading platform applicable Terms &amp; Conditions and that prior to the
						transfer, you have not breached this Agreement or the Terms &amp; Conditions of the NFT Trading
						Platform.</p>

				<p>NINEBOLT is not responsible for any transactions between you and a third party, including
						using any NFT Trading Platform to transfer your Licensed NFTs. NINEBOLT shall have no responsibilities or
						liability with respect to any transfer you enact on any of these NFT Trading Platforms.</p>

				<h2>8. Termination of License.</h2>
				<p>The licensed rights granted to you in Section 5 of this Agreement shall
						automatically terminate if you perform any of the following actions: 1) you sell, trade, donate, or
						otherwise transfer your Licensed NFT in any manner; (2) you breach any terms of this Agreement or any
						Terms &amp; Conditions for the designated NFT Trading Platform; or (3) you engage in or initiate any
						legal actions against NINEBOLT and/or any of their affiliates, and each of their respective officers,
						directors, members, affiliates, agents, or employees.</p>

				<p>Upon termination of your licensed rights, you
						must immediately cease and desist all activities and rights granted to you from Section 5. This includes
						ceasing all activities involving the Licensed NFT for your own personal use and creating or selling
						physical merchandise that incorporates the Art of the Licensed NFT.</p>

				<h2>9. Assumption of Risk</h2>
				<p>You understand and agree that the Licensed NFTs are made solely for entertainment purposes only. You
						agree and understand that that: (1) the market and prices for a blockchain asset are extremely volatile
						and subjective and collectible blockchain assets, such as the Licensed NFTs, have no inherent or
						intrinsic value, and fluctuations in the price of such blockchain assets could materially and adversely
						affect the price and value of your Licensed NFTs; (2) there are risks involved with using internet-based
						currency and asset, including, but not limited to, risk of hardware, software, internet connection
						failure, risk of malicious software, and risk that unauthorized parties may gain access to your personal
						information including such information and assets contained in your digital wallet or elsewhere; (3)
						internet-based currency and assets are not currently regulated by any regulatory regimes, and new
						regulations or policies that may materially affect the value of any Licensed NFTs; (4) there is an
						inherent risk that you may lose access due to loss of private keys, custodial error, or even purchaser
						error; (5) there are risks related to taxation; and (6) NINEBOLT does not make any guarantees or
						representations about the availability of the Licensed NFTs or the art or that they will host the
						Licensed NFTs or the Art in any specific location for any specific period of time.</p>

				<p>You agree that
						you have received sufficient information to make an informed decision regarding the decision to purchase
						or otherwise obtain the Licensed NFTs and that you understand and agree that you are solely responsible
						for determining the value, nature, and appropriateness of the above risks for yourself.</p>

				<h2>10. Limitation of Liability</h2>
				<p>ALL LICENSED NFTS ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT
						WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. NINEBOLT AND ITS AFFILIATES HEREBY DISCLAIM ALL WARRANTIES,
						EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
						A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN NO EVENT SHALL NINEBOLT BE LIABLE TO YOU FOR ANY INDIRECT,
						EXTRAORDINARY, EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF
						DATA, GOODWILL, WORK STOPPAGE, DIMINUTION OF VALUE OR ANY OTHER INTANGIBLE LOSS, TECHNOLOGY FAILURE, OR
						MALFUNCTION, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE RELATED TO ANY LICENSED NFT OR OTHERWISE)
						HOWEVER ARISING, EVEN IF NINEBOLT KNOWS THERE IS A POSSIBILITY OF SUCH DAMAGE.</p>

				<h2>11. Release</h2>

				<p>IF
						YOU ARE A RESIDENT OF A JURISDICTION THAT REQUIRES A SPECIFIC STATEMENT REGARDING RELEASE THEN THE
						FOLLOWING APPLIES.&nbsp; FOR EXAMPLE, CALIFORNIA RESIDENTS MUST, AS A CONDITION OF THIS AGREEMENT, WAIVE
						THE APPLICABILITY OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, “A GENERAL RELEASE DOES NOT EXTEND
						TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
						EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT
						WITH THE DEBTOR."&nbsp; YOU HEREBY WAIVE THIS SECTION OF THE CALIFORNIA CIVIL CODE.&nbsp; YOU HEREBY
						WAIVE ANY SIMILAR PROVISION IN LAW, REGULATION, OR CODE THAT HAS THE SAME INTENT OR EFFECT AS THE
						AFOREMENTIONED RELEASE.&nbsp; YOU RELEASE US FROM ANY LIABILITY RELATING TO OUR SITE OR NINEBOLT CONTENT, AND
						YOU RELEASE US, OUR DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AND AGENTS FROM ANY CLAIMS AND DAMAGES,
						KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST US. THIS
						RELEASE DOES NOT APPLY TO NEW JERSEY USERS.</p>

				<p>If you are a resident of a jurisdiction that requires a
						specific statement regarding release, then the following applies. For example, California residents must,
						as a condition of this Agreement, waive the applicability of California Civil Code Section 1542 which
						states, “a general release does not extend to claims which the creditor does not know or suspect to exist
						in his or her favor at the time of executing the release, which if known by him or her must have
						materially affected his or her settlement with the debtor.” You hereby waive this section of the
						California Civil Code. You hereby waive any similar provision in law, regulation, or code that has the
						same intent or effect as the aforementioned release. You release us from any liability relating to our
						site or NINEBOLT Content, and you release us, our directors, officers, employees, affiliates, and agents from
						any claims and damages, known and unknown, arising out of or in any way connected with any claim you have
						against us. This release does not apply to New Jersey users.</p>

				<h2>12. Digital Millenium Copyright Act of 1988</h2>
				<p>The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright
						owners who believe that material appearing on the Internet infringes their rights under the U.S.
						copyright law. If you believe in good faith that materials hosted by NINEBOLT infringe your copyright, you, or
						your agent may send to NINEBOLT a notice requesting that the material be removed or access to it be blocked.
						Any notification by a copyright owner or a person authorized to act on its behalf that fails to comply
						with requirements of the DMCA shall not be considered sufficient notice and shall not be deemed to confer
						upon NINEBOLT actual knowledge of facts or circumstances from which infringing material or acts are evident.
						If you believe in good faith that a notice of copyright infringement has been wrongly filed against you,
						the DMCA permits you to send to NINEBOLT a counter-notice. All notices and counter-notices must meet the
						then-current statutory requirements imposed by the DMCA; see http://www.loc.gov/copyright for details.
						NINEBOLT’s Copyright Agent for notice of claims of copyright infringement or counter notices can be reached as
						follows: through a direct message to NINEBOLT’s Twitter at @NINEBOLT.</p>

				<h2>13. Site Availability and Modification</h2>
				<p>Although we attempt to provide continuous Site availability to you, we do not
						guarantee that the Site will always be available, work, or be accessible at any particular time.&nbsp;We
						reserve the right to alter, modify, update, or remove portions of our Site at any time.&nbsp; We may
						conduct such modifications to our Site for security reasons, intellectual property, legal reasons, or
						various other reasons at our discretion; however, nothing in this section obligates us to take measures
						to update the Site for security, legal, or other reasons.</p>

				<h2>14. Unauthorized Conduct</h2>
				<p>When accessing or using our Site, you are solely responsible for your actions, and you agree to abide by the following rules of conduct:</p>
				<ul>
					<li>You agree not to copy, distribute or disclose any part of the Site in any medium, including without limitation by any automated or non-automated “scraping;”</li>
					<li>You agree not to attempt to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the Site;</li>
					<li>You agree not to use any robot, spider, crawler, scraper or other automated means or interface not provided by us to access the Site or to extract or export data collected through the Site;</li>
					<li>You agree not to take any action that imposes, or may impose at our sole discretion, an unreasonable or disproportionately large load on our infrastructure;&nbsp;You agree that you will not hold NINEBOLT, any of its affiliates, or its third-party providers responsible for your use of the Site;</li>
					<li>You agree not to violate any requirements, procedures, policies, or regulations of networks connected to NINEBOLT;</li>
					<li>You agree not to interfere with or disrupt the Site;</li>
					<li>You agree not to post anything contrary to our public image, goodwill, or reputation;</li>
					<li>You agree to not violate any US federal laws, state laws, or local laws while using the Site; and</li>
					<li>You agree not to use the Site in any way that is: misleading, unlawful, defamatory, obscene, invasive, threatening, harmful, or harassing.</li>
				</ul>

				<p>If you are discovered to be undertaking any of the aforementioned actions your privileges to use our Site
						may, at our discretion, be terminated or suspended.&nbsp;NINEBOLT reserves the right to suspend or terminate
						your access at any time without notice or explanation.&nbsp;</p>

				<h2>15. Indemnity</h2>
				<p>You agree to defend, indemnify and hold harmless NINEBOLT, its officers, directors, employees, affiliates, third-parties, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from:&nbsp;</p>
				<ul>
					<li>your use of any NINEBOLT Site;&nbsp;</li>
					<li>your use of any NINEBOLT affiliate services;</li>
					<li>your violation of any term of this Agreement; or</li>
					<li>arising out of any claim that you have breached any provision of these Terms &amp; Conditions.</li>
				</ul>

				<p>This defense and indemnification obligation will survive this Agreement. You also agree that you have a
						duty to defend us against such claims and we may require you to pay for an attorney(s) of our choice in
						such cases.&nbsp; You agree that this indemnity extends to requiring you to pay for our reasonable
						attorneys’ fees, court costs, and disbursements.&nbsp; In the event of a claim such as one described in
						this paragraph, we may elect to settle with the party/parties making the claim and you shall be liable
						for the damages as though we had proceeded with a trial.</p>

				<h2>16. Choice of Law &amp; Venue</h2>
				<p>This Agreement and any action related to this Agreement shall be governed by the laws in force in the State of
						California and US Federal law. Foreign laws do not apply. The offer and acceptance of this contract are
						deemed to have occurred in the State of California.</p>

				<h2>17. Forum</h2>
				<p>By using this Site, you agree
						that: (1) any claim, dispute, or controversy you may have against us, NINEBOLT, or the Site arising out of,
						relating to, or connected in any way with this Agreement or any Licensed NFTs purchased shall be resolved
						exclusively by final and binding arbitration administered by the American Arbitration Association (“AAA”)
						and conducted before a single arbitrator pursuant to the applicable Consumer Rules and Procedures
						established by AAA (“Rules and Procedures”); (2) the claim or dispute must be brought within one (1) year
						of the first date of the event giving rise to such action (does not apply to New Jersey users) and the
						arbitration shall be held in Los Angeles, California or at such other location as may be mutually agreed
						upon by you and NINEBOLT; (3) the arbitrator shall apply California law consistent with the Federal
						Arbitration Act and applicable statutes of limitations, and shall honor claims of privilege recognized at
						law; (4) there shall be no authority for any claims to be arbitrated on a class or representative basis;
						arbitration can decide only your and/or NINEBOLT’ individual claims; and the arbitrator may not consolidate or
						join the claims of other persons or parties who may be similarly situated (this does not apply to New
						Jersey users); (5) both parties will bear their own costs of representation and filing for the dispute;
						(6) where possible and allowed for under the AAA Rules and Procedures both parties shall be entitled to
						appear electronically or telephonically for all proceedings;&nbsp; and (7) with the exception of subpart
						(4) above, if any part of this arbitration provision is deemed to be invalid, unenforceable or illegal,
						or otherwise conflicts with the Rules and Procedures established by AAA, then the balance of this
						arbitration provision shall remain in effect and shall be construed in accordance with its terms as if
						the invalid, unenforceable, illegal or conflicting provision were not contained herein. If, however,
						subpart (4) is found to be invalid, unenforceable, or illegal, then the entirety of this Arbitration
						Provision shall be null and void, and neither you nor NINEBOLT shall be entitled to arbitrate their dispute.
						For more information on AAA and its Rules and Procedures, users may visit the AAA website at<a
							href="http://www.adr.org/"> http://www.adr.org</a>.&nbsp; In the event that any portion of this
						arbitration provision is found to be unenforceable or void, both parties agree to settle any disputes
						arising out of this Agreement in a court of competent jurisdiction located in or near Los Angeles,
						California.</p>

				<h2>18. Class Action Waiver</h2>
				<p>You and NINEBOLT agree that any proceedings to resolve or
						litigate any dispute whether through a court of law or arbitration shall be solely conducted on an
						individual basis.&nbsp; You agree that you will not seek to have any dispute heard as a class action,
						representative action, collective action, or private attorney general action.</p>

				<h2>19. Severability</h2>
				<p>In the event that a provision of this Agreement is found to be unlawful, conflicting
						with another provision of the Agreement, or otherwise unenforceable, the Agreement will remain in force
						as though it had been entered into without that unenforceable provision being included in it.</p><p>If
						two or more provisions of this Agreement or any other agreement you may have with NINEBOLT are deemed to
						conflict with each other’s operation, you agree that NINEBOLT shall have the sole right to elect which
						provision remains in force.&nbsp;&nbsp;</p>

				<h2>20. Waiver</h2><p>We reserve all rights permitted to us
						under this Agreement as well as under the provisions of any applicable law.&nbsp; Our non-enforcement of
						any particular provision or provisions of this Agreement or any applicable law should not be construed as
						our waiver of the right to enforce that same provision under the same or different circumstances at any
						time in the future.</p>

				<h2>21. No Joint Venture</h2>
				<p>This Agreement shall not constitute a joint venture, partnership, employment, or agency relationship between the parties.</p>

				<h2>22. Survival</h2>
				<p>All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.&nbsp; You agree that we are not required to provide you with access to our Site and may terminate our Site or your access to the Site at any time and for any reason.</p>

				<h2>23. Age</h2>
				<p>Licensed NFTs are not intended nor targeted towards children in any way. All users who access the Site or buy Licensed NFTs must be 18 years of age (or above the legal age of your jurisdiction, whichever is greater) or older </p>

				<h2>24. Amendments</h2>
				<p>We may amend this Agreement from time to time. When we amend this Agreement, we will update this page and indicate the date that it was last modified, or we may email you. You may refuse to agree to the amendments, but if you do, you must immediately cease using our Site. Amended Terms &amp; Conditions will apply to the use of this Site from the date of publication of the amended Terms &amp; Conditions of this Site.</p>

				<hr/>

				<h2 className="h6">You Own Your NFT</h2>
				<p>By connecting your digital wallet and/or minting a {config.name} NFT through the smart contract, you gain full and complete ownership of your {config.name} NFT. Limitations of Liability for Gas, Failed Transactions, and other Bugs.</p>
				<p>Steps have been taken to ensure that there are no bugs in our smart contract and that the NFT launch is as smooth and seamless as possible. However, as we have seen in several NFT projects, there can been issues. You agree to not hold NFTs accountable for any losses you may incur as a consequence of minting your NFT. These potential losses include any gas fees for failed transactions, and any excessive gas fees during the minting process.</p>

				<h2 className="h6">Not Intended as Investments</h2>
				<p>{config.name} NFTs serve exclusively as Non-Fungible Tokens for you to collect. They are not intended for any investment purposes. We make absolutely no promise or guarantee that these NFTs will hold any particular value once minted.</p>

				<h2 className="h6">Class Action Waiver</h2>
				<p>You agree to waive any class action status, and any legal dispute around the {config.name} NFT project.</p>

				<h2 className="h6">Arbitration</h2>
				<p>In the event that a legal dispute arises from anything related to the NFTs project, you agree to bring the case to binding arbitration according to appropriate Federal guidelines.</p>

				<h2 className="h6">Jurisdiction and Choice of Law</h2>
				<p>You agree that for purposes of any legal dispute, you will be subject to the jurisdiction of the United States and that any legal proceeding will be brought in the United States.</p>

				<h2 className="h6">Taxes</h2>
				<p>You are entirely and solely responsible for any tax liability which may arise from minting, selling or owning your {config.name} NFT(s).</p>

			</article>

				</div>
			</div>

		</div>
	);
}

export default Terms;

const CONNECT = {
	name: 'blockchain/CONNECT',
	REQUEST: 'blockchain/CONNECT_REQUEST',
	SUCCESS: 'blockchain/CONNECT_SUCCESS',
	FAILURE: 'blockchain/CONNECT_FAILURE',
};

const ACCOUNT_UPDATE = {
	name: 'blockchain/ACCOUNT_UPDATE',
	REQUEST: 'blockchain/ACCOUNT_UPDATE_REQUEST',
	SUCCESS: 'blockchain/ACCOUNT_UPDATE_SUCCESS',
	FAILURE: 'blockchain/ACCOUNT_UPDATE_FAILURE',
}

export default {
	CONNECT,
	ACCOUNT_UPDATE,
};

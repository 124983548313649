import { createSelector } from 'reselect';


const getOwnerTokens = (state) => {
	console.log('state', state);
	let tokens = state.tokens.owned.slice();
	tokens.sort((a, b) => b - a);
	return tokens;
};

const getTotalSupply = (state) => {
	return state.tokens.totalSupply;
};

const getRemainingAttributes = (state, { attributeType }) => {
	const attributes = state.tokens.remainingAttributes[attributeType];
	return attributes;
}

export {
	getOwnerTokens,
	getTotalSupply,
	getRemainingAttributes,
}

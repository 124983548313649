import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { fork, all } from 'redux-saga/effects';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import middleware, { sagaMiddleware } from '@@Redux/middleware';

import blockchain, { saga as blockchainSaga } from '@@Redux/blockchain';
import tokens, { saga as tokensSaga } from '@@Redux/tokens';
import forms, { saga as formsSaga } from '@@Redux/forms';
import apiStatus from '@@Redux/apiStatus';


// noinspection JSUnresolvedVariable
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = (history) => combineReducers({
	router: connectRouter(history),
	apiStatus,
	blockchain,
	forms,
	tokens,
});

function* rootSaga() {
	yield all([
		fork(blockchainSaga),
		fork(formsSaga),
		fork(tokensSaga),
	])
}

const appState = {};
export const history = createBrowserHistory();

const configureStore = (/* preloadedState */) => {
	const store = createStore(
		rootReducer(history),
		appState,
		composeEnhancer(
			applyMiddleware(
				routerMiddleware(history),
				...middleware,
			),
		),
	);

	sagaMiddleware.run(rootSaga);

	return store;
};

export default configureStore;

import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import stringify from 'json-stringify-safe';
import Mint from '@@Components/Mint';
import WalletConnection from '@@Components/WalletConnection';
import { colors } from '@@Constants';
import OwnerTokens from '@@Components/OwnerTokens';
import EarlyBirdForm from '@@Components/EarlyBirdForm';
import { operations as blockchainOperations, selectors as blockchainSelectors } from '@@Redux/blockchain';
import { operations as tokenOperations, selectors as tokenSelectors }  from '@@Redux/tokens';


const Home = ({
	contract,
	signer,
	address,
	getTotalSupply,
	totalSupply,
}) => {

	useEffect(() => {
		getTotalSupply();
	}, [getTotalSupply])

	return (
		<div className="container position-relative">
			<div className="row justify-content-center mb-4">
				<div className="col-12 text-center">

					<div className="col text-center">
						<div className="h3 mb-4 text-center">Open for Minting Soon</div>
						<p>Join the <a href="https://discord.gg/aahErHzz4W" target="_blank" rel="noopener nofollow noreferrer">Bingotown Discord Server</a> for Updates and Early Bird Specials.</p>
					</div>

					{/*Contract: {stringify(contract)}*/}
					{/*Signer: {stringify(contract)}*/}
{/*
					{contract && signer && (
						<Mint contract={contract} signer={signer}/>
					)}

					{!contract && !signer && (
						<WalletConnection/>
					)}*/}

					{/*<EarlyBirdForm />*/}

				</div>
			</div>

			{contract && (
				<div>
					Total Supply: {totalSupply}
				</div>
			)}

			{/*{contract && signer && address && (
				<div className="row">
					<div className="col-12">
						<OwnerTokens contract={contract} address={address}/>
					</div>
				</div>
			)}*/}
		</div>
	);

};

const mapState = (state) => {

	const blockchain = blockchainSelectors.getBlockchain(state);
	const { contract, signer, address } = blockchain;

	const totalSupply = tokenSelectors.getTotalSupply(state);

	return {
		contract,
		signer,
		address,
		totalSupply,
	};
};

const mapDispatch = {
	getTotalSupply: tokenOperations.getTotalSupply,
};

export default connect(
	mapState,
	mapDispatch,
)(Home);



import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import Logo from '../img/logo-bingotown.svg';
import {colors} from '@@Constants';


const Header = () => {

	const colorValues = [colors.BLUE, colors.YELLOW, colors.CHERRY, colors.GREEN].map((c) => c.hex);
	const [bgColor, setBgColor] = useState(colorValues[0]);
	const [fgColor, setFgColor] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			const rand = Math.floor(Math.random()*colorValues.length);
			setBgColor(colorValues[rand]);
			setFgColor(bw => bw === 0 ? 1 : 0);
		}, 1000);
		return () => clearInterval(timer);
	}, []);

	return (
		<header id="header" /*style={{ backgroundColor: bgColor.toString() }}*/>
			<div style={{ minHeight: '40vh', justifyContent: "center", alignItems: 'center', display: 'flex' }}>
				<div className="container py-5">
					<div className="row justify-content-center">
						<div className="col-10 col-xl-8">

							<div className="text-center py-5">

								<div className="mb-3">
									<svg x="0px" y="0px" viewBox="0 0 540 540" style={{ width: '15%', marginBottom: '1rem' }} >
										<path d="M125.3,492.7L270.5,45.3l145.2,447.4L35,216h471" fill="#fff"/>
									</svg>
								</div>

								Welcome to
								<NavLink to="/" className="logo mb-2" activeClassName="selected">
									<Logo fill='white' />
								</NavLink>

								<div>Classic 75-ball Bingo on the Blockchain</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;

import actions from './actions';

const submitEarlyBird = (params) => (dispatch) => {
	console.info('[operation] submitEarlyBird()', params);
	return dispatch(actions.submitEarlyBird.request(params));
};

const verifyRecaptcha = (params) => async (dispatch) => {
	console.info('[operation] verifyRecaptcha()', params);
	await dispatch(actions.verifyRecaptcha.request(params));
	return true;
}


export {
	submitEarlyBird,
	verifyRecaptcha,
};

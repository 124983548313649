import { combineReducers } from 'redux';
import produce from 'immer';

import types from './types';


const owned = (state = [], action) => produce(state, draft => {
	switch (action.type) {
		case types.OWNER_TOKENS.REQUEST:
			return;
		case types.OWNER_TOKENS.SUCCESS:

			const { tokens } = action.payload;

			return tokens.map((token) => Number(token));

		case types.TOKEN_MINTED.SUCCESS:
			const { tokenId } = action.payload;
			draft[tokenId] = Number(tokenId);
			break;
		default:
			return state;
	}
});


const totalSupply = (state = 0, action) => produce(state, draft => {
	switch (action.type) {
		case types.TOTAL_SUPPLY.SUCCESS: {
			const { totalSupply } = action.payload;
			return Number(totalSupply);
		}
		default: return state;
	}
});

const remainingAttributes = (state = {}, action) => produce(state, draft => {
	switch (action.type) {
		case types.REMAINING_ATTRIBUTES.SUCCESS: {
			const { attributes } = action.payload;

			console.log('attr', attributes);

			attributes.forEach((attributeType, i) => {
				draft[i] = attributes[i].map(x => Number(x));
			});


			return;
		}
		default: return state;
	}
});

const reducer = combineReducers({
	owned,
	totalSupply,
	remainingAttributes,
});

export default reducer;

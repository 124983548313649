import React, { useEffect, Fragment } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';
import {useEtherBalance, useEthers} from '@usedapp/core';
import {ethers} from 'ethers';


const WalletConnection = () => {

	let wallet = false;
	const { activateBrowserWallet, account } = useEthers();
	const userBalance = useEtherBalance(account);

	console.log('typeof window.ethereum', typeof window.ethereum);
	if (typeof window.ethereum !== 'undefined') {
		wallet = true;
	}
	console.log('wallet', wallet);

	useEffect(() => {
	}, []);

	const handleConnectWallet = () => {
		console.log('Connecting Wallet...');
		try {
			activateBrowserWallet(undefined, true);
		} catch (err) {
			console.error('Unable to connect wallet:', err);
		}
	}

	return (
		<Fragment>
			{!wallet && (
				<Fragment>
					Please install a crypto wallet such as <a href="https://metamask.io/index.html">MetaMask</a> before proceeding.
				</Fragment>
			)}

			{account && (
				<div>
					<p className="mb-3"><span className="badge bg-success">Connected</span></p>
					{userBalance && (
						<h1>{Number(ethers.utils.formatEther(userBalance)).toFixed(4)} </h1>
					)}
					<p>Address: {account}</p>
				</div>
			)}

			{wallet && !account && (
				<div className="">
					<button className="btn btn-primary mb-2" onClick={handleConnectWallet}>Connect Wallet</button>
				</div>
			)}

		</Fragment>

	);
}

export default WalletConnection;

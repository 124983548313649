// https://coolors.co/568484-d8461e-d27900-c1272d-763472-00597b-555555-587e64-db7575-a0aa55
module.exports = {
	BUBBLEGUM: { name: 'Bubblegum', hex: '#EA3D64' },
	CHERRY: { name: 'Cherry', hex: '#C31D23' },
	ORANGE: { name: 'Orange', hex: '#F04A24' },
	YELLOW: { name: 'Yellow', hex: '#F09908' },
	LIME: { name: 'Lime', hex: '#A0B533' },
	GREEN: { name: 'Green', hex: '#3D7B3A' },
	TEAL: { name: 'Teal', hex: '#067482' },
	SKY: { name: 'Sky', hex: '#2F99B9' },
	BLUE: { name: 'Blue', hex: '#0E6BA5' },
	BERRY: { name: 'Berry', hex: '#9D2868' },
	GRAPE: { name: 'Grape', hex: '#563864' },
	WOOD: { name: 'Wood', hex: '#93482B' }, // COLOR_12: { name: 'COLOR_12', hex: '#626766' },
	STONE: { name: 'Stone', hex: '#5C7E90' },
}

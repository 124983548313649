import { combineReducers } from 'redux';
import produce from 'immer';

import types from './types';

const earlyBird = (state = {}, action) => produce(state, draft => {
	switch (action.type) {
		case types.EARLY_BIRD_SUBMIT.SUCCESS: {
			return action.payload;
		}
		default: return state;
	}
});

const recaptcha = (state = {}, action) => produce(state, draft => {
	switch (action.type) {
		case types.RECAPTCHA_VERIFY.SUCCESS: {
			return action.payload;
		}
		default: return state;
	}
});

const reducer = combineReducers({
	earlyBird,
	recaptcha,
});

export default reducer;

import { eventChannel, END } from 'redux-saga';
import { all, take, takeEvery, takeLatest, put, fork, select, call, actionChannel, cancel } from 'redux-saga/effects';
import { ethers, Wallet } from 'ethers';
import config from '@@Config';

import { getBlockchain } from '@@Redux/blockchain/selectors';

import actions from './actions';
import types from './types';
import {getFunctions, httpsCallable} from 'firebase/functions';


/**
 Workers
 */
function* getOwnerTokens(action) {

	console.log('xx getOwnerTokens');

	const params = action.payload;

	try {
		const blockchain = yield select(getBlockchain);
		const { contract, address } = blockchain;

		console.log('ad0', address);

		// const tokens = yield contract.tokensOfOwner(address);


		// const totalSupply = yield contract.totalSupply().toNumber();

		for (let i = 0; i < 1000; i++) {
			const owner = yield contract.ownerOf(1);
			console.log('owner', owner);
		}

		// for (let i = 0; i < totalSupply; i++) {
		//
		// }
		//

		//yield put(actions.getOwnerTokens.success({ tokens }));
	}
	catch (err) {
		console.error(err);
		yield put(actions.getTokens.failure(err));
	}
}

function* mintToken(action) {
	const params = action.payload;
	const { quantity } = params;

	console.log('quantity', quantity);

	try {

		const blockchain = yield select(getBlockchain);
		const { contract, address } = blockchain;

		const chainId = "0x".concat(Number(1337).toString(16));

		// try {
		// 	console.log('wallet_switchEthereumChain...');
		// 	yield ethereum.request({
		// 		method: 'wallet_switchEthereumChain',
		// 		params: [{
		// 			chainId,
		// 		}],
		// 	});
		// } catch (switchError) {
		// 	// This error code indicates that the chain has not been added to MetaMask.
		// 	if (switchError.code === 4902) {
		// 		try {
		// 			console.log('wallet_addEthereumChain...');
		// 			yield ethereum.request({
		// 				method: 'wallet_addEthereumChain',
		// 				params: [{
		// 					chainId,
		// 					rpcUrl: config.polygon.rpc,
		// 				}],
		// 			});
		// 		} catch (err) {
		// 			console.err(err)
		// 		}
		// 	}
		// 	// handle other "switch" errors
		// }

		const totalAmount = ethers.utils.parseEther((config.token.price * quantity).toString());

		const options = { value: totalAmount, gasLimit: 300000 * quantity };
		// var charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		// let seed = window.crypto.getRandomValues(new Uint32Array(25));
		// let result = '';
		// for (let i = 0; i < seed.length; i++) {
		// 	result += charset[seed[i] % charset.length];
		// }
		// console.log('seed', result);
		yield contract.mint(quantity, options)

		yield put(actions.mintToken.success());

		yield fork(watchTokenMinted);
		console.log('NFT minted');

	}
	catch (err) {
		console.error(err);
		yield put(actions.mintToken.failure(err));
	}
}

function* getTotalSupply() {
	try {

		const blockchain = yield select(getBlockchain);
		const { contract } = blockchain;

		const totalSupply = yield contract.totalSupply();
		yield put(actions.getTotalSupply.success({ totalSupply }));
	} catch (err) {
		yield put(actions.getTotalSupply.failure(err));
	}
}

function* getRemainingAttributes(action) {
	// const params = action.payload;

	// console.log('params', params);

	try {
		const blockchain = yield select(getBlockchain);
		const { contract } = blockchain;

		// const { attributeType } = params;

		const attributes = yield contract.getRemainingAttributes();

		console.log('attributes', attributes);

		yield put(actions.getRemainingAttributes.success({ attributes }));
	}
	catch (err) {
		console.error(err);
		yield put(actions.getRemainingAttributes.failure(err));
	}

}

function* createMintedChannel() {
	const blockchain = yield select(getBlockchain);
	const { contract, address } = blockchain;

	return eventChannel((emit) => {
		const mintHandler = (owner, tokenId, serial) => {
			if (owner === address) {
				const data = { owner, serial, tokenId };
				emit(data);
			}
		};

		contract.on('Minted', mintHandler);

		return () => {
			contract.off('Minted', mintHandler);
		};
	});
}

/**
 * Watchers
 */
function* watchOwnerTokens() {
	try {
		yield takeLatest(types.OWNER_TOKENS.REQUEST, getOwnerTokens);
	} catch(err) {
		yield put(actions.getOwnerTokens.failure(err));
	}
}

function* watchTokenMint() {
	try {
		yield takeLatest(types.TOKEN_MINT.REQUEST, mintToken);
	} catch(err) {
		yield put(actions.mintToken.failure(err));
	}
}

export function* watchTokenMinted() {

	const functions = getFunctions();
	const generateAndUpload = httpsCallable(functions, 'generateAndUpload');

	const channel = yield call(createMintedChannel);


	while (true) {

		try {
			yield put(actions.tokenMinted.request());
			const payload = yield take(channel);

			const { tokenId, serial } = payload;

			const params = {
				tokenId: Number(tokenId),
				serial: Number(serial).toString().padStart(13, '0'),
			};

			// yield call(generateAndUpload, params);
			yield put(actions.tokenMinted.success(payload));

			yield put(actions.getTotalSupply.request());

			break;
		}
		catch (err) {
			console.error('minted error:', err);
			yield put(actions.tokenMinted.failure(err));
		}
	}
}

function* watchTotalSupply() {
	try {
		yield takeLatest(types.TOTAL_SUPPLY.REQUEST, getTotalSupply);
	} catch(err) {
		yield put(actions.getTotalSupply.failure(err));
	}
}

function* watchRemainingAttributes() {
	try {
		yield takeEvery(types.REMAINING_ATTRIBUTES.REQUEST, getRemainingAttributes);
	} catch(err) {
		yield put(actions.getRemainingAttributes.failure(err));
	}
}

/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchOwnerTokens),
		fork(watchTokenMint),
		fork(watchTotalSupply),
		fork(watchRemainingAttributes),
	]);
}

export default sagas;

import React, { useEffect } from 'react';
import {connect} from 'react-redux';

import config from '@@Config';
import { operations as tokenOperations, selectors as tokenSelectors } from '@@Redux/tokens';

import NFT from './NFT';


const OwnerTokens = ({
	getOwnerTokens,
	tokens,
}) => {

	useEffect(() => {
		getOwnerTokens();
	}, [getOwnerTokens]);


	if (tokens.length > 0) {
		return (
			<div className="my-4">
				<h1 className="mb-3 text-center">Your Cards</h1>
				<div className="row">
					{tokens.map(x => {
						return (
							<div key={x} className="col-sm-6 col-md-4 col-lg-3">
								<NFT tokenId={x} baseURI={config.baseURI} />
							</div>
						);
					})}
				</div>

			</div>
		);
	}
	return null;
};

const mapState = (state) => {
	const tokens = tokenSelectors.getOwnerTokens(state);
	return {
		tokens,
	};
};

const mapDispatch = {
	getOwnerTokens: tokenOperations.getOwnerTokens,
};

export default connect(
	mapState,
	mapDispatch,
)(OwnerTokens);

import produce from 'immer';

import types from './types';


const initialState = {
	provider: null,
	signer: null,
	contract: null,
	address: null,
	account: null,
};

const blockchain = (state = initialState, action) => produce(state, draft => {
	switch (action.type) {
		case types.CONNECT.REQUEST:
			return;
		case types.CONNECT.SUCCESS:

			const { provider, signer, contract, address, chainId } = action.payload;
			draft.provider = provider;
			draft.signer = signer;
			draft.contract = contract;
			draft.address = address;
			draft.chainId = Number(chainId);
			return;

		case types.ACCOUNT_UPDATE.SUCCESS:
			const { account  } = action.payload;
			draft.account = account;
			return;

		default:
			return state;
	}
});

export default blockchain;

import React, { useEffect, useState, Fragment } from 'react';


const NFT = ({
	baseURI,
	tokenId,
	URI,
}) => {

	const [metadata, setMetadata] = useState(null);

	useEffect(() => {
		let url;

		if (URI) {
			url = URI
		} else if (baseURI && tokenId) {
			url = `${baseURI}/${tokenId}`;
		}

		console.log(url);
		async function getMetadata() {
			const response = await fetch(url, {
				mode: 'cors',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json'
				},
			});
			const json = await response.json();

			setMetadata(json)
		}
		getMetadata();
	}, [tokenId]);

	return (
		<Fragment>
			{metadata && (
				<div className="card p-3 mx-1 mb-4" style={{ height: 'calc(100% - 1.5em)'}}>
					<div className="card-img-top">
						<img src={metadata.image} className="w-100" />
					</div>
					<div className="card-body text-dark">
						<h1 className="h3 text-center">{metadata.name}</h1>
						<p className="text-dark text-sm text-center">{metadata.description}</p>
						<hr />
						<dl className="mb-0">
							<dt>Body</dt>
							<dd>{metadata.attributes[0].value}</dd>
							<dt>Face</dt>
							<dd>{metadata.attributes[1].value}</dd>
							<dt>Color</dt>
							<dd>{metadata.attributes[2].value}</dd>
							<dt>Rank</dt>
							<dd>{metadata.attributes[3].value}</dd>
							<dt>Theme</dt>
							<dd>{metadata.attributes[4].value}</dd>
							{/*<dt>Series</dt>
							<dd>{metadata.attributes[5].value}</dd>*/}
						</dl>

					</div>
				</div>
			)}
		</Fragment>
	);
}

export default NFT

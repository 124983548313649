import actions from './actions';


const getOwnerTokens = (params) => (dispatch) => {
	console.info('[operation] getOwnerTokens()', params);
	return dispatch(actions.getOwnerTokens.request(params));
};

const mintToken = (params) => (dispatch) => {
	console.info('[operation] mintToken()', params);
	return dispatch(actions.mintToken.request(params));
};

const getTotalSupply = () => (dispatch) => {
	console.info('[operation] getTotalSupply()');
	return dispatch(actions.getTotalSupply.request());
};

const getRemainingAttributes = () => (dispatch) => {
	console.info('[operation] getRemainingAttributes()');
	return dispatch(actions.getRemainingAttributes.request());
};

export {
	getOwnerTokens,
	mintToken,
	getTotalSupply,
	getRemainingAttributes,
};

import { eventChannel, END } from 'redux-saga';
import { all, take, takeEvery, takeLatest, put, fork, select, call, actionChannel, cancel } from 'redux-saga/effects';
import { ethers, Wallet } from 'ethers';
import config from '@@Config';


import actions from './actions';
import types from './types';
import { getFunctions, httpsCallable } from 'firebase/functions';


/**
 Workers
 */
function* submitEarlyBird(action) {

	const params = action.payload;
	console.log('xxxx', params);

	try {
		const functions = getFunctions();
		const addMessage = httpsCallable(functions, 'addMessage');
		const data = yield call(addMessage, params);

		yield put(actions.submitEarlyBird.success(data));

	}
	catch (err) {
		console.error(err);
		yield put(actions.submitEarlyBird.failure(err));
	}
}

function* verifyRecaptcha(action) {

	const params = action.payload || {};

	// const callback = async () => {
	// 	console.log('callback');
	//
	// 	// const token =
	// 	// const token = await grecaptcha.execute();
	//
	// 	// console.log('recaptchaScore', recaptchaScore);
	// 	// submitEarlyBird({ text: 'test123'});
	// 	/*}).then((data) => {
	// 		console.log(data, 'recaptchaScore', recaptchaScore);
	// 	});*/
	// 	params.token = token;
	// };

	// yield call([window.grecaptcha, 'ready']);
	// // const token = yield call([window.grecaptcha, 'execute'], config.recaptcha.siteKey);
	// const token = yield  call([grecaptcha.execute(config.recaptcha.siteKey, { action: 'submit' });
	//
	// console.log('tok1', token)


	try {
		const functions = getFunctions();
		const verifyRecaptcha = httpsCallable(functions, 'verifyRecaptcha');
		const { data } = yield call(verifyRecaptcha, params);
		//
		console.log('data', data);

		yield put(actions.verifyRecaptcha.success(data));

		const submitParams = data;
		yield put(actions.submitEarlyBird.request(submitParams));
	}
	catch (err) {
		console.error(err);
		yield put(actions.verifyRecaptcha.failure(err));
	}
}

/**
 * Watchers
 */
function* watchSubmitEarlyBird() {
	try {
		yield takeLatest(types.EARLY_BIRD_SUBMIT.REQUEST, submitEarlyBird);
	} catch(err) {
		yield put(actions.submitEarlyBird.failure(err));
	}
}

function* watchVerifyRecaptcha() {
	try {
		yield takeLatest(types.RECAPTCHA_VERIFY.REQUEST, verifyRecaptcha);
	} catch(err) {
		yield put(actions.verifyRecaptcha.failure(err));
	}
}

/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchSubmitEarlyBird),
		fork(watchVerifyRecaptcha),
	]);
}

export default sagas;

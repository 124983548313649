const OWNER_TOKENS = {
	name: 'tokens/OWNER_TOKENS',
	REQUEST: 'tokens/OWNER_TOKENS_REQUEST',
	SUCCESS: 'tokens/OWNER_TOKENS_SUCCESS',
	FAILURE: 'tokens/OWNER_TOKENS_FAILURE',
};

const TOKEN_MINT = {
	name: 'tokens/TOKEN_MINT',
	REQUEST: 'tokens/TOKEN_MINT_REQUEST',
	SUCCESS: 'tokens/TOKEN_MINT_SUCCESS',
	FAILURE: 'tokens/TOKEN_MINT_FAILURE',
};

const TOKEN_MINTED = {
	name: 'tokens/TOKEN_MINTED',
	REQUEST: 'tokens/TOKEN_MINTED_REQUEST',
	SUCCESS: 'tokens/TOKEN_MINTED_SUCCESS',
	FAILURE: 'tokens/TOKEN_MINTED_FAILURE',
};

const TOTAL_SUPPLY = {
	name: 'tokens/TOTAL_SUPPLY',
	REQUEST: 'tokens/TOTAL_SUPPLY_REQUEST',
	SUCCESS: 'tokens/TOTAL_SUPPLY_SUCCESS',
	FAILURE: 'tokens/TOTAL_SUPPLY_FAILURE',
};


const REMAINING_ATTRIBUTES = {
	name: 'tokens/REMAINING_ATTRIBUTES',
	REQUEST: 'tokens/REMAINING_ATTRIBUTES_REQUEST',
	SUCCESS: 'tokens/REMAINING_ATTRIBUTES_SUCCESS',
	FAILURE: 'tokens/REMAINING_ATTRIBUTES_FAILURE',

}

export default {
	OWNER_TOKENS,
	TOKEN_MINT,
	TOKEN_MINTED,
	TOTAL_SUPPLY,
	REMAINING_ATTRIBUTES,
};

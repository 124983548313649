import {all, takeLatest, put, fork, call} from 'redux-saga/effects';
import { ethers, Wallet } from 'ethers';
import config from '@@Config';

import { watchTokenMinted } from '@@Redux/tokens/sagas';

import actions from './actions';
import types from './types';

/**
 Workers
 */
function* connect(action) {
	const params = action.payload;

	try {

		let provider;
		if (typeof window.ethereum !== 'undefined' || (typeof window.web3 !== 'undefined')) {
			provider = new ethers.providers.Web3Provider(window.ethereum);

			// const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
			// const curProvider = window['ethereum'] || window.web3.currentProvider
			// provider = await detectEthereumProvider();

			const signer = yield provider.getSigner();
			const chainId = yield ethereum.request({ method: 'net_version' });

			console.log('chainId', chainId, typeof chainId);

			// if (chainId === '1337') {

				const contract = new ethers.Contract(config.contract.address, config.contract.abi, signer);

				console.log('contract', contract);

				const accounts = yield window.ethereum.request({ method: 'eth_requestAccounts' });
				console.log('accounts', accounts);

				const address = yield signer.getAddress();


				// Add listeners
				window.ethereum.on('accountsChanged', function* (accounts) {
					yield put(actions.updateAccount.success(accounts[0]));
				});

				/*window.ethereum.on('chainChanged', () => {
					window.location.reload();
				});*/

				const result = {
					provider,
					signer,
					contract,
					address,
					chainId,
				};

				yield put(actions.connect.success(result));

			// } else {
			// 	yield put(actions.connect.failure('Change network to Polygon'));
			// }

		} else {
			yield put(actions.connect.failure('Please install Metamask'));
		}

	}
	catch (err) {
		console.error(err);
		yield put(actions.connect.failure(err));
	}
}

function* accountUpdate(action) {
	const account = action.payload;

	yield put(actions.accountUpdate.success({ account }));
}

/**
 * Watchers
 */
function *watchConnect() {
	try {
		yield takeLatest(types.CONNECT.REQUEST, connect);
	} catch(err) {
		yield put(actions.accountUpdate.failure(err));
	}
}

function *watchAccountUpdate() {
	try {
		yield takeLatest(types.ACCOUNT_UPDATE.REQUEST, accountUpdate);
	} catch(err) {
		yield put(actions.connect.failure(err));
	}
}


/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchConnect),
		fork(watchAccountUpdate),
	]);
}

export default sagas;

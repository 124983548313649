import types from './types';


const getOwnerTokens = {
	request: () => ({
		type: types.OWNER_TOKENS.REQUEST,
		meta: {
			message: 'Retrieving Tokens',
		}
	}),
	success: (data) => ({
		type: types.OWNER_TOKENS.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.OWNER_TOKENS.FAILURE,
		error: true,
		payload: err,
	}),
};

const mintToken = {
	request: (params) => ({
		type: types.TOKEN_MINT.REQUEST,
		payload: params,
		meta: {
			message: 'Waiting to Mint Token: Please Confirm Wallet Transaction',
		}
	}),
	success: (data) => ({
		type: types.TOKEN_MINT.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.TOKEN_MINT.FAILURE,
		error: true,
		payload: err,
	}),
};

const tokenMinted = {
	request: (params) => ({
		type: types.TOKEN_MINTED.REQUEST,
		payload: params,
		meta: {
			message: 'Minting Token: Please Wait.',
		}
	}),
	success: (data) => ({
		type: types.TOKEN_MINTED.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.TOKEN_MINTED.FAILURE,
		error: true,
		payload: err,
	}),
};

const getTotalSupply = {
	request: () => ({
		type: types.TOTAL_SUPPLY.REQUEST,
		meta: {
			message: 'Retrieving Total Supply',
		}
	}),
	success: (data) => ({
		type: types.TOTAL_SUPPLY.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.TOTAL_SUPPLY.FAILURE,
		error: true,
		payload: err,
	}),
};

const getRemainingAttributes = {
	request: () => ({
		type: types.REMAINING_ATTRIBUTES.REQUEST,
		meta: {
			message: 'Retrieving Remaining Attributes',
		}
	}),
	success: (data) => ({
		type: types.REMAINING_ATTRIBUTES.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.REMAINING_ATTRIBUTES.FAILURE,
		error: true,
		payload: err,
	}),
};



export default {
	getOwnerTokens,
	mintToken,
	tokenMinted,
	getTotalSupply,
	getRemainingAttributes,
}

import React, { useState } from 'react';
import Modal from 'react-modal';

import Logo from '@@Images/logo-bingotown.svg';


const Welcome = () => {

	const [isFirstVisit, setIsFirstVisit] = useState(true);
	Modal.setAppElement('body');

	return (
		<Modal
			isOpen={isFirstVisit}
			className="welcome"
			overlayClassName="modal-backdrop"
			// contentLabel={message}
		>
			{/*<button onClick={closeModal}>close</button>*/}
			<div className="text-light text-center p-lg-5 w-100">

				<svg x="0px" y="0px" viewBox="0 0 540 540" style={{ width: '25%', marginBottom: '1rem' }} >
					<path d="M125.3,492.7L270.5,45.3l145.2,447.4L35,216h471" fill="#fff"/>
				</svg>

				<div>Welcome to</div>
				<div className="mt-2 mb-4">
					<div className="mb-3"><Logo fill='white' /></div>
				</div>
				<button type="button" className="btn btn-outline-secondary" onClick={() => setIsFirstVisit(false)}>Enter</button>
			</div>
		</Modal>
	);
}

export default Welcome;

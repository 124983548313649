import React, { useEffect, useState, Fragment } from 'react';
import {connect} from 'react-redux';
import { useEtherBalance, useEthers } from '@usedapp/core';
import {BigNumber, ethers} from 'ethers';
import Modal from 'react-modal';

import config from '@@Config';

import detectEthereumProvider from '@metamask/detect-provider';
import WalletConnection from './WalletConnection';
import {getFunctions, httpsCallable} from 'firebase/functions';

import NFT from './NFT';
import {operations as tokenOperations, selectors as tokenSelectors} from '@@Redux/tokens';



const POLYGON_RPC = 'https://polygon-rpc.com/';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '25%'
	},
};


const Mint = ({
	mintToken,
}) => {

	const { activateBrowserWallet, account } = useEthers();
	const userBalance = useEtherBalance(account);

	const [isMinting, setIsMinting] = useState(false);
	const [showResult, setShowResult] = useState(false);
	const [resultURI, setResultURI] = useState('');


	useEffect(() => {
		Modal.setAppElement('body');

	}, []);


	const handleMint = async (quantity) => {
		mintToken({ quantity });


		// const chainId = "0x".concat(Number(1337).toString(16));
		//
		// try {
		// 	console.log('wallet_switchEthereumChain...');
		// 	await ethereum.request({
		// 		method: 'wallet_switchEthereumChain',
		// 		params: [{
		// 			chainId,
		// 		}],
		// 	});
		// } catch (switchError) {
		// 	// This error code indicates that the chain has not been added to MetaMask.
		// 	if (switchError.code === 4902) {
		// 		try {
		// 			console.log('wallet_addEthereumChain...');
		// 			await ethereum.request({
		// 				method: 'wallet_addEthereumChain',
		// 				params: [{
		// 					chainId,
		// 					rpcUrl: POLYGON_RPC,
		// 				}],
		// 			});
		// 		} catch (err) {
		// 			console.err(err)
		// 		}
		// 	}
		// 	// handle other "switch" errors
		// }
		//
		//
		// const totalAmount = ethers.utils.parseEther((config.token.price * quantity).toString());
		//
		// const options = { value: totalAmount };
		//
		// await contract.mint(quantity, Date.now().toString(), options);
		// console.log('NFT minted');
		// setIsMinting(true);
	};

	return (
		<Fragment>
			Mint

			<div className="mb-4">
				<strong>Buy Now for {config.token.price} {config.token.currency.symbol} each</strong>
			</div>

			{account && (
				<div>
					<p className="mb-3"><span className="badge bg-success">Connected</span></p>

					{userBalance && (
						<h1>{ethers.utils.formatEther(userBalance)}</h1>
					)}

					<p>Address: {account}</p>

					<div className="row justify-content-center">
						<div className="col-auto">
							<button className="btn btn-lg btn-primary" onClick={() => handleMint(1)}>
								Mint 1 Card {config.token.price} {config.token.currency.symbol}
							</button>
						</div>
						<div className="col-auto">
							<button className="btn btn-lg btn-primary" onClick={() => handleMint(3)}>
								Mint 3 Cards {Math.round(3 * config.token.price * 0.8 * 100) / 100} {config.token.currency.symbol}
							</button>
						</div>
						<div className="col-auto">
							<button className="btn btn-lg btn-primary" onClick={() => handleMint(5)}>
								Mint 5 Cards {Math.round(5 * config.token.price * 0.7 * 100) / 100} {config.token.currency.symbol}
							</button>
						</div>
					</div>

					{/*<Modal*/}
					{/*	isOpen={isMinting}*/}
					{/*	// onAfterOpen={afterOpenModal}*/}
					{/*	// onRequestClose={closeModal}*/}
					{/*	style={customStyles}*/}
					{/*	contentLabel="Minting"*/}
					{/*>*/}
					{/*	<button onClick={closeModal}>close</button>*/}
					{/*	<div className="text-dark">*/}
					{/*	Please Wait...*/}
					{/*	</div>*/}
					{/*</Modal>*/}

					{/*{showResult && resultURI && (*/}
					{/*<Modal*/}
					{/*	isOpen={showResult}*/}
					{/*	// onAfterOpen={afterOpenModal}*/}
					{/*	onRequestClose={() => setShowResult(false)}*/}
					{/*	style={customStyles}*/}
					{/*	contentLabel="Minting"*/}
					{/*>*/}

					{/*	<div className="w-100">*/}
					{/*		<h2 className="h4 text-dark text-center">Congratulations!</h2>*/}
					{/*		<NFT URI={resultURI} />*/}
					{/*	</div>*/}

					{/*	<button onClick={() => setShowResult(false)}>close</button>*/}

					{/*</Modal>*/}

				</div>
			)}
			{!account && (
				<div className="d-flex justify-content-center">
					<WalletConnection />
				</div>
			)}
		</Fragment>
	);
};

const mapState = () => {
	return {};
};

const mapDispatch = {
	mintToken: tokenOperations.mintToken,
};

export default connect(
	mapState,
	mapDispatch,
)(Mint);

import React, { useEffect } from 'react';
import {operations as formOperations} from '@@Redux/forms';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';

import config from '@@Config';

import {selectors as formSelectors} from '@@Redux/forms';


const EarlyBirdForm = ({
	submitEarlyBird,
	verifyRecaptcha,
	recaptchaScore,
}) => {
	const handleFormSubmit = () => {
		console.log('submit');


		window.grecaptcha.ready(async () => {
			window.grecaptcha.execute(config.recaptcha.siteKey, { action: 'submit' })
				.then((token) => {
					console.log('ttt', token);
					return verifyRecaptcha({ token });
				}).then((data) => {
					console.log('aaa', data);
				});

			//
			// console.log('recaptchaScore', recaptchaScore);
			// submitEarlyBird({ text: 'test123'});
			/*}).then((data) => {
				console.log(data, 'recaptchaScore', recaptchaScore);
			});*/
		});
	}

	return (

		<div>

			<Helmet>
				<script src={`https://www.google.com/recaptcha/api.js?render=${config.recaptcha.siteKey}`} />
			</Helmet>

			{recaptchaScore}
			<button type="button" className="btn btn-primary" onClick={handleFormSubmit}>Submit</button>
		</div>

	);
}


const mapState = (state) => {
	const recaptchaScore = formSelectors.getRecaptchaScore(state);
	return {
		recaptchaScore,
	};
};

const mapDispatch = {
	submitEarlyBird: formOperations.submitEarlyBird,
	verifyRecaptcha: formOperations.verifyRecaptcha,
};

export default connect(
	mapState,
	mapDispatch,
)(EarlyBirdForm);

import types from './types';


const submitEarlyBird = {
	request: (params) => ({
		type: types.EARLY_BIRD_SUBMIT.REQUEST,
		payload: params,
		meta: {
			message: 'Submitting',
		}
	}),
	success: (data) => ({
		type: types.EARLY_BIRD_SUBMIT.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.EARLY_BIRD_SUBMIT.FAILURE,
		error: true,
		payload: err,
	}),
};


const verifyRecaptcha = {
	request: (params) => ({
		type: types.RECAPTCHA_VERIFY.REQUEST,
		payload: params,
		meta: {
			message: 'Verifying Recaptcha',
		}
	}),
	success: (data) => ({
		type: types.RECAPTCHA_VERIFY.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.RECAPTCHA_VERIFY.FAILURE,
		error: true,
		payload: err,
	}),
};




export default {
	submitEarlyBird,
	verifyRecaptcha,
}

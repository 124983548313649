import types from './types';


const connect = {
	request: () => ({
		type: types.CONNECT.REQUEST,
		meta: {
			message: 'Connecting',
		}
	}),
	success: (data) => ({
		type: types.CONNECT.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.CONNECT.FAILURE,
		error: true,
		payload: err,
	}),
};


const accountUpdate = {
	request: () => ({
		type: types.ACCOUNT_UPDATE.REQUEST,
		meta: {
			message: 'Account Updating',
		}
	}),
	success: (data) => ({
		type: types.ACCOUNT_UPDATE.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.ACCOUNT_UPDATE.FAILURE,
		error: true,
		payload: err,
	}),
};


export default {
	connect,
	accountUpdate,
}

import { createSelector } from 'reselect';



const getRecaptchaScore = (state) => {
	if (state.forms.recaptcha) {
		return state.forms.recaptcha.score;
	}

};

export {
	getRecaptchaScore,
}

import React from 'react';
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';


const FAQ = () => {
	return (
		<div className="container">

			<Helmet title="Frequently Asked Questions" />

			<div className="row justify-content-center">
				<div className="col-md-8">

					<div className="mb-5">
						<h1>FAQ</h1>
					</div>

					<Accordion defaultActiveKey="0">
						<Accordion.Item eventKey="0">
							<Accordion.Header>How do I mark spots on my card?</Accordion.Header>
							<Accordion.Body>
								All card metadata and images will automatically update with each game draw. No action is needed on your part, with the exception of refreshing your NFT's metadata on OpenSea (or other platform of choice). You can verify your NFT's artwork is up-to-date by comparing the game and draw numbers at the bottom of each card with the Bingotown website or designated Discord channels.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<Accordion.Header>How do I get <em>BINGO!</em>?</Accordion.Header>
							<Accordion.Body>
								Each game will have set 'winning' bingo pattern. This is usually a straight or diagonal line of 5 marked squares on a single card. Other patterns might include four corners, postage stamp, or even a coverall/blackout. The designated pattern will be announced before each game.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<Accordion.Header>...and will I actually win money if I get <em>BINGO!</em>?</Accordion.Header>
							<Accordion.Body>
								The Bingotown Treasury continually seeks and makes offers (via OpenSea) on cards it deems fit for the walls of the town's Hall of Fame. Cards that have the <em>BINGO!</em> trait are its most coveted target. Ultimately, it is up to you to decide to accept any offer for your NFT. If you do choose to accept an offer from the Treasury, you will also be airdropped a new, freshly-minted card with new numbers and traits - ready to play the next game.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3">
							<Accordion.Header>How many draws/games will there be?</Accordion.Header>
							<Accordion.Body>
								The number of draws per game is variable depending on when the first <em>BINGO!</em> is achieved. The number of games is endless, in theory. Currently no end is set.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="4">
							<Accordion.Header>Why isn't this a free mint?</Accordion.Header>
							<Accordion.Body>
								Though free mints may be the trend as of late, many of these projects/communities seem to fail and fail very fast. This aside, the core reason is to fund the town's treasury and in turn, add a higher level of excitement to owning a Bingotown bingo card.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="5">
							<Accordion.Header>Is there rarity?</Accordion.Header>
							<Accordion.Body>
								Yes, in a sense. The card background color,  card ink color, stamp ink color and stamp pattern all play into the NFT's rarity.  More importantly however, the number of 'free' spaces is variable. Most cards will have one single 'free' space in the center (as with traditional bingo cards) but some may have 2, 3 or even 4 free spaces appearing on single card, greatly increasing the chance of <em>BINGO!</em>. These, of course, are more rare and should be valued as such.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="6">
							<Accordion.Header>Will my card's numbers change?</Accordion.Header>
							<Accordion.Body>
								No. Each Bingotown card token is minted with a verifiable seed stored on the blockchain and will not change. This seed is used to generate the card's numbers (and other traits) utilizing a Mersenne Twister pseudorandom number generator.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="6">
							<Accordion.Header>Is there a burn mechanism?</Accordion.Header>
							<Accordion.Body>
								Yes. When the Treasury acquires tokens, it may decide to burn them in order to remove the cards from gameplay and if applicable, issue new tokens to seller. The Department of Streets and Floors may also acquire tokens through its sweeps. They too, may decide to burn tokens and in turn, make new mints available.
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>
			</div>

		</div>
	);
}

export default FAQ;

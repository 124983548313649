const EARLY_BIRD_SUBMIT = {
	name: 'forms/EARLY_BIRD_SUBMIT',
	REQUEST: 'forms/EARLY_BIRD_SUBMIT_REQUEST',
	SUCCESS: 'forms/EARLY_BIRD_SUBMIT_SUCCESS',
	FAILURE: 'forms/EARLY_BIRD_SUBMIT_FAILURE',
};

const RECAPTCHA_VERIFY = {
	name: 'forms/RECAPTCHA_VERIFY',
	REQUEST: 'forms/RECAPTCHA_VERIFY_REQUEST',
	SUCCESS: 'forms/RECAPTCHA_VERIFY_SUCCESS',
	FAILURE: 'forms/RECAPTCHA_VERIFY_FAILURE',
};

export default {
	EARLY_BIRD_SUBMIT,
	RECAPTCHA_VERIFY,
};
